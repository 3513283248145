<route>
{
  "meta": {
    "permission": [
      "sales.add_order", "sales.change_order"
    ]
  }
}
</route>

<template>
  <v-card id="salePk" class="mt-5 elevation-1 mx-3">
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent="handleSubmit(submit)">
        <i-toolbar dontSave :title="setSalePKTitle" :loading="loading">
          <v-divider class="mx-4" inset vertical></v-divider>
          <i-btn
            color="white"
            icon="icon-request"
            text
            :small="$vuetify.breakpoint.smAndDown"
            :title="$t('sale_order')"
            @click="$router.push({ name: 'sale' })"
          ></i-btn>
          <template v-slot:arrowLeft>
            <div class="salepk-arrow">
              <i-btn
                v-if="step > 1"
                color="text-dark"
                icon="fa-arrow-left"
                text
                :small="$vuetify.breakpoint.smAndDown"
                :title="$t('back')"
                @click="back"
              >
              </i-btn>
            </div>
          </template>
          <template v-slot:arrowRight>
            <div style="display: block; min-width: 64px">
              <i-btn
                text
                :small="$vuetify.breakpoint.smAndDown"
                icon="fa-arrow-right"
                :title="$t('next')"
                color="text-dark"
                v-if="showNext()"
                @click="next()"
              >
              </i-btn>
            </div>
          </template>
          <template v-slot:submit>
            <i-btn
              text
              :title="$tc('save')"
              v-if="!invoiceCreating"
              :small="$vuetify.breakpoint.smAndDown"
              icon="fa-save"
              color="text-dark"
              :loading="loading"
              @click="submit()"
            >
            </i-btn>
          </template>
        </i-toolbar>
        <v-card-text class="px-0">
          <v-stepper style="z-index: 0" class="elevation-0" v-model="step">
            <v-stepper-header class="elevation-0">
              <v-stepper-step :step="1" edit-icon="$complete" editable>
                {{ $t('basic') }}
              </v-stepper-step>
              <v-divider v-if="order.pk"></v-divider>
              <v-stepper-step
                v-if="orderBtn.product && order.pk"
                :complete="order.mainStatus !== 'draft' && step !== 2"
                :step="2"
              >
                {{ $t('selection', { value: $tc('product', 2) }) }}
              </v-stepper-step>
              <v-divider v-if="order.pk"></v-divider>
              <v-stepper-step
                v-if="orderBtn.quotation && order.pk"
                edit-icon="$complete"
                :editable="order.mainStatus !== 'draft'"
                :step="3"
              >
                {{ $t('quote') }}
              </v-stepper-step>
              <v-divider
                v-if="
                  (order.mainStatus === 'in_process' ||
                    order.mainStatus === 'production_ready' ||
                    order.mainStatus === 'delivery' ||
                    order.mainStatus === 'finished') &&
                  cartlist.length > 0
                "
              ></v-divider>
              <v-stepper-step
                edit-icon="$complete"
                v-if="order.mainStatus === 'delivery'"
                :step="4"
                :editable="order.mainStatus === 'delivery'"
                v-show="order.mainStatus === 'delivery' && cartlist.length > 0"
              >
                {{ $t('assignDriver') }}
              </v-stepper-step>
              <v-divider
                v-if="
                  (order.mainStatus === 'in_process' ||
                    order.mainStatus === 'production_ready' ||
                    order.mainStatus === 'finished') &&
                  cartlist.length > 0
                "
              ></v-divider>
              <v-stepper-step
                :step="4"
                edit-icon="$complete"
                :editable="
                  order.mainStatus === 'in_process' ||
                  order.mainStatus === 'production_ready' ||
                  order.mainStatus === 'finished'
                "
                v-show="
                  (order.mainStatus === 'in_process' ||
                    order.mainStatus === 'production_ready' ||
                    order.mainStatus === 'finished') &&
                  orderBtn.designerorder &&
                  cartlist.length > 0
                "
              >
                {{ $t('designer') }}
              </v-stepper-step>
              <v-divider
                v-if="
                  (order.mainStatus === 'in_process' ||
                    order.mainStatus === 'production_ready' ||
                    (order.mainStatus === 'delivery' && isBalanceSufficient) ||
                    order.mainStatus === 'finished') &&
                  cartlist.length > 0
                "
              ></v-divider>
              <v-stepper-step
                edit-icon="$complete"
                :step="5"
                :editable="
                  order.mainStatus === 'in_process' ||
                  order.mainStatus === 'production_ready' ||
                  order.mainStatus === 'finished'
                "
                v-show="
                  (order.mainStatus === 'in_process' ||
                    order.mainStatus === 'production_ready' ||
                    order.mainStatus === 'finished') &&
                  orderBtn.designerorder &&
                  cartlist.length > 0
                "
              >
                {{ $t('assignProduction') }}
              </v-stepper-step>
              <v-stepper-step
                :step="5"
                edit-icon="$complete"
                :editable="order.mainStatus === 'delivery'"
                v-if="
                  order.mainStatus === 'delivery' &&
                  cartlist.length > 0 &&
                  isBalanceSufficient
                "
              >
                {{ $t('delivery') }}
              </v-stepper-step>

              <v-divider
                v-if="
                  (order.mainStatus === 'in_process' ||
                    order.mainStatus === 'production_ready' ||
                    order.mainStatus === 'finished') &&
                  cartlist.length > 0
                "
              ></v-divider>
              <v-stepper-step
                :step="6"
                edit-icon="$complete"
                :editable="
                  order.mainStatus === 'in_process' ||
                  order.mainStatus === 'production_ready' ||
                  order.mainStatus === 'finished'
                "
                v-if="
                  (order.mainStatus === 'in_process' ||
                    order.mainStatus === 'production_ready' ||
                    order.mainStatus === 'finished') &&
                  orderBtn.invoices &&
                  cartlist.length > 0
                "
              >
                {{ $t('invoice') }}
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content :step="1" class="px-0 py-0">
                <v-row class="mt-0 pb-6">
                  <v-col
                    class="align-center justify-center align-self-center"
                    cols="12"
                    md="5"
                  >
                    <v-skeleton-loader
                      v-if="loading || loadingIMG"
                      type="image"
                      tile
                    ></v-skeleton-loader>
                    <i-image
                      v-else
                      v-model="imageUrl"
                      :readonly="
                        order.orderMain == 'canceled' ||
                        order.orderMain == 'finished'
                      "
                      :file.sync="order.thumbnail"
                    />
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-row>
                      <!-- Concurrencia del cliente -->
                      <v-col v-if="orderBtn.customer" cols="12 pb-3">
                        <h6>{{ $tc('concurrence') }}</h6>
                        <div class="container-v-switch pt-2">
                          <label
                            :class="permanent_customer ? '' : 'active'"
                            for="permanent_customer"
                          >
                            {{ $t('sporadic') }}
                          </label>
                          <v-switch
                            id="permanent_customer"
                            class="mt-0 pt-0"
                            color="primary"
                            name="permanent_customer"
                            v-model="permanent_customer"
                            @click="sortCustomers()"
                          ></v-switch>
                          <label
                            :class="permanent_customer ? 'active' : ''"
                            for="permanent_customer"
                          >
                            {{ $t('permanent') }}
                          </label>
                        </div>
                      </v-col>

                      <!-- Cliente -->
                      <v-col
                        v-if="orderBtn.customer"
                        cols="12"
                        :md="
                          customer != null && customer.email != undefined
                            ? 6
                            : 12
                        "
                      >
                        <dialcustomer
                          container="modal"
                          @updated="addCustomer"
                          v-model="customerfield"
                        ></dialcustomer>
                        <ValidationProvider
                          vid="customer"
                          :name="$tc('customer', 1)"
                          :rules="{ required: step === 1 }"
                          v-slot="{ errors }"
                        >
                          <v-skeleton-loader
                            v-if="loading"
                            type="list-item-avatar"
                            tile
                          ></v-skeleton-loader>
                          <v-autocomplete
                            v-show="!loading"
                            class="secondary--text"
                            :append-icon="
                              customers.length > 10 ? 'fa-search' : ''
                            "
                            autocomplete="off"
                            :item-text="
                              (item) =>
                                permanent_customer
                                  ? `${item.company_name}`
                                  : `${item.first_name} ${item.last_name}`
                            "
                            item-value="pk"
                            outlined
                            :clearable="
                              order.mainStatus === 'draft' &&
                              customers.length > 10
                            "
                            :disabled="order.mainStatus !== 'draft'"
                            :error-messages="errors[0]"
                            :items="customers"
                            :label="$tc('customer', 1)"
                            :prepend-inner-icon="
                              order.mainStatus === 'draft' &&
                              orderBtn.customerAdd &&
                              orderBtn.level &&
                              orderBtn.terms
                                ? 'fa-plus'
                                : ''
                            "
                            @click:prepend-inner="customerfield = true"
                            v-model="order.customer"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{
                                    data.item.company_name
                                      ? `${data.item.company_name} -`
                                      : ''
                                  }}
                                  {{ data.item.first_name }}
                                  {{ data.item.last_name }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </ValidationProvider>
                      </v-col>

                      <!-- Email -->
                      <v-col
                        v-if="
                          orderBtn.customer &&
                          order.customer != null &&
                          customer != null &&
                          customer.email != undefined
                        "
                        cols="12"
                        :md="
                          customer != null && customer.email != undefined
                            ? 6
                            : 12
                        "
                      >
                        <v-text-field
                          color="secondary"
                          outlined
                          disabled
                          :label="$tc('email', 1)"
                          v-model="customer.email"
                        />
                      </v-col>

                      <!-- Contactos? -->
                      <v-col
                        v-if="
                          orderBtn.customer &&
                          order.customer != null &&
                          customer != null &&
                          customer.contacts != undefined
                        "
                        cols="12"
                      >
                        <v-text-field
                          color="secondary"
                          disabled
                          outlined
                          :label="$tc('contact', 1)"
                          :value="`${customer.first_name} ${customer.last_name}`"
                        />
                      </v-col>

                      <!-- Nombre del proyecto -->
                      <v-col cols="12">
                        <ValidationProvider
                          vid="order_name"
                          :name="$tc('order_name', 1)"
                          :rules="{ required: step === 1, max: '32' }"
                          v-slot="{ errors }"
                        >
                          <v-skeleton-loader
                            v-if="loading"
                            type="text"
                            tile
                          ></v-skeleton-loader>
                          <v-text-field
                            v-else
                            color="secondary"
                            outlined
                            validate-on-blur
                            :error-messages="errors[0]"
                            :label="$t('order_name')"
                            :disabled="order.mainStatus != 'draft'"
                            v-model="order.nameOrder"
                          />
                        </ValidationProvider>
                      </v-col>

                      <!-- Fecha de la orden -->
                      <v-col cols="12" md="6" lg="4">
                        <i-date-picker
                          :loading="loading"
                          v-model="order.date"
                          :label="$tc('dateOrder', 1)"
                          :disabled="order.mainStatus != 'draft'"
                          color="primary"
                          :max="new Date().toISOString().substr(0, 10)"
                        />
                      </v-col>

                      <!-- Fecha de compromiso -->
                      <v-col cols="12" md="6" lg="4">
                        <ValidationProvider
                          vid="promise_date"
                          :name="$tc('promise_date', 1)"
                          rules=""
                          v-slot="{ errors }"
                        >
                          <i-date-picker
                            :error-messages="errors[0]"
                            :loading="loading"
                            v-model="order.promise_date"
                            :label="$tc('promise_date', 1)"
                            :disabled="order.mainStatus != 'draft'"
                            color="primary"
                            :min="order.date"
                          />
                        </ValidationProvider>
                      </v-col>

                      <!-- Finalizado? -->
                      <v-col
                        v-if="order.mainStatus === 'finished'"
                        cols="12"
                        md="6"
                        lg="4"
                      >
                        <v-text-field
                          outlined
                          disabled
                          prepend-icon="fa-calendar"
                          readonly
                          :label="$tc('dateDone', 1)"
                          :value="dateFormat(order.dateDone)"
                        />
                      </v-col>

                      <!-- Entrega parcial -->
                      <v-col cols="12" md="6" lg="4">
                        <v-switch
                          class="v-switch mt-2"
                          color="primary"
                          :disabled="
                            order.mainStatus != 'draft' &&
                            order.mainStatus != 'quote'
                          "
                          :label="$t('partial')"
                          v-model="order.partialDelivery"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    orderBtn.customer &&
                    order.customer != null &&
                    customer != null &&
                    customer.address_set != undefined
                  "
                  class="mt-0 pb-3"
                >
                  <v-col
                    cols="12"
                    md="6"
                    v-for="(address, inx) in customer.address_set"
                    :key="`address-${inx}`"
                  >
                    <v-card class="elevation-1 infocard">
                      <v-card-text :class="'primary--text'">
                        <h3 class="flex-title h6 mb-4">
                          <span>
                            {{
                              address.address_type === 'SHIP'
                                ? $t('shipping_address')
                                : $t('billing_address')
                            }}
                          </span>
                          <span>
                            <v-icon
                              v-text="
                                address.address_type == 'SHIP'
                                  ? 'fa fa-shipping-fast'
                                  : 'fa fa-money-bill'
                              "
                              color="primary"
                            />
                          </span>
                        </h3>
                        <span>{{ `${address.address1}, ` }}</span>
                        <span v-show="address.address2 != ''">
                          {{ `${address.address2}, ` }}
                        </span>
                        <span>{{ `${address.city}, ` }}</span>
                        <span>{{ `${address.state}, ` }}</span>
                        <span v-show="address.zip_code != ''">
                          {{ `${address.zip_code}` }}
                        </span>
                        <span>
                          {{
                            countries[address.country] != undefined
                              ? countries[address.country]
                              : ''
                          }}
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content class="pa-0" :step="2">
                <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
                  <v-col
                    cols="12"
                    :md="showCart ? 8 : 12"
                    v-if="
                      order.pk !== '' &&
                      (order.mainStatus === 'draft' ||
                        order.mainStatus === 'quote')
                    "
                  >
                    <cart
                      v-if="orderBtn.product"
                      mode="cart"
                      type="sale"
                      :floatElement="showCart"
                      :status-order="order.mainStatus"
                      :order="order.pk"
                      :orderName="order.nameOrder"
                      :orderImage="orderImage"
                      :customer="customer"
                      :customerTax="customer === null ? false : customer.tax"
                    />
                  </v-col>
                </v-row>
                <shoplist
                  v-if="
                    orderBtn.orderproduct &&
                    orderBtn.orderstatus &&
                    orderBtn.changeprice &&
                    orderBtn.imageapproval &&
                    orderBtn.imageparameters &&
                    orderBtn.typedate &&
                    orderBtn.suborderdate &&
                    orderBtn.orderdetails &&
                    orderBtn.details
                  "
                  type="sale"
                  :cartlist.sync="cartlist"
                  :subtotalOrder.sync="order.subtotal"
                  :totalOrder.sync="order.total"
                  @update:totalOrder="internalChange = true"
                  :taxOrder.sync="order.tax"
                  :orderName="order.nameOrder"
                  :orderImage="orderImage"
                  :customerTax="customer === null ? false : customer.tax"
                  :type_customer="
                    customer
                      ? customer.level_id === null
                        ? 0
                        : customer.level_id
                      : 0
                  "
                  v-model="showCart"
                  :status="order.mainStatus"
                  :order="order.pk"
                />
              </v-stepper-content>
              <v-stepper-content class="pa-0" :step="3">
                <quote
                  v-if="
                    orderBtn.quotation &&
                    order.pk != '' &&
                    order.customer != null &&
                    (step === 3 || order.mainStatus !== 'draft') &&
                    customer != null &&
                    customer.address_set != undefined
                  "
                  :status.sync="order.mainStatus"
                  :cartlist.sync="cartlist"
                  :order.sync="order"
                  @done="getOrder"
                  @update:status="internalChange = true"
                  @subtotalOrder="order.subtotal = $event"
                  @totalOrder=";(order.total = $event), (internalChange = true)"
                  @taxOrder="order.tax = $event"
                  @addProd="addProducts"
                  :customer="customer === null ? '' : customer"
                />
              </v-stepper-content>
              <v-stepper-content
                :step="4"
                v-if="order.mainStatus === 'delivery'"
              >
                <assignuser
                  v-if="orderBtn.designerorder && cartlist.length > 0"
                  :items="cartlist"
                  mode="delivery"
                />
              </v-stepper-content>
              <v-stepper-content
                :step="4"
                v-if="order.mainStatus !== 'delivery'"
              >
                <assignuser
                  v-if="orderBtn.designerorder && cartlist.length > 0"
                  :items="cartlist"
                  mode="designer"
                />
              </v-stepper-content>

              <v-stepper-content
                :step="5"
                v-if="order.mainStatus === 'delivery'"
              >
                <delivery
                  v-if="
                    isBalanceSufficient &&
                    cartlist.length > 0 &&
                    orderBtn.orderproduct &&
                    orderBtn.delivery &&
                    order.pk !== '' &&
                    (order.mainStatus === 'delivery' ||
                      order.mainStatus === 'finished')
                  "
                  :order="order"
                  :partialDelivery="order.partialDelivery"
                  :customer="customer === null ? '' : customer"
                  :balance="balance"
                  :cartlist.sync="cartlist"
                />
              </v-stepper-content>

              <v-stepper-content
                :step="5"
                v-if="order.mainStatus !== 'delivery'"
              >
                <assignuser
                  v-if="orderBtn.designerorder && cartlist.length > 0"
                  :items="cartlist"
                  mode="production"
                />
              </v-stepper-content>
              <v-stepper-content
                class="pa-0"
                :step="6"
                v-if="order.mainStatus !== 'delivery'"
              >
                <invoicefield
                  @changeMode="invoiceCreating = $event"
                  v-if="
                    cartlist.length > 0 &&
                    orderBtn.invoices &&
                    order.pk !== '' &&
                    step == 6 &&
                    (order.mainStatus === 'in_process' ||
                      order.mainStatus === 'finished')
                  "
                  :order="order.pk"
                />
                <v-col>
                  <v-row>
                    <v-col cols="3" v-if="customer !== null">
                      <v-alert type="info" outlined color="primary">
                        <i-money
                          :value="
                            customer.has_credit
                              ? customer.customercredit_set[0].credit_limit
                              : '0.00'
                          "
                          mode="text"
                          :label="`${$tc('has_credit', 1)} =`"
                        />
                      </v-alert>
                    </v-col>
                    <v-col cols="3">
                      <v-switch
                        v-model="order.partialDelivery"
                        :label="$t('partial')"
                        color="primary"
                        :disabled="
                          order.mainStatus != 'draft' &&
                          order.mainStatus != 'quote'
                        "
                        key="partial-input"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-col>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions class="salesman-info subtitle-2">
          <span class="font-weight-black pr-1">
            {{ `${$tc('salesman', 1)}: ` }}
          </span>
          {{ order.name_salesman }}
        </v-card-actions>
      </form>
    </ValidationObserver>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import cart from '../product/index.vue'
import quote from '../../components/sale/quotation.vue'
import shoplist from '../../components/product/shoplist.vue'
import dialcustomer from '../customer/_pk.vue'
import invoicefield from '../../components/invoice/invoiceDocument.vue'
import assignuser from '../../components/sale/assignItem.vue'
import delivery from '../../components/sale/delivery.vue'

export default {
  components: {
    cart,
    shoplist,
    dialcustomer,
    quote,
    invoicefield,
    assignuser,
    delivery
  },
  data() {
    return {
      customerfield: false,
      loadingIMG: false,
      dialog: false,
      step: 1,
      invoiceCreating: false,
      menu: false,
      menu1: false,
      load: false,
      loading: false,
      isLoading: false,
      customer: null,
      countries: [],
      customers: [],
      searchCustomer: '',
      internalChange: false,
      showCart: false,
      cartlist: [],
      imageUrl: null,
      notification: true,
      orderImage: null,
      balance: 0,
      permanent_customer: false,
      order: {
        pk: '',
        nameOrder: '',
        date: '',
        code: '',
        customer: null,
        salesman: '',
        name_salesman: '',
        mainStatus: 'draft',
        tax: 0,
        subtotal: 0,
        total: 0,
        delivery: 0,
        discount: 0,
        dateDone: null,
        promise_date: null,
        partialDelivery: false,
        thumbnail: null,
        company_id: null
      }
    }
  },
  watch: {
    cartlist(val) {
      if (val.length > 0) {
        this.showCart = true
      }
    },
    'order.customer': {
      handler(val) {
        let inx = this.customers.findIndex((m) => val == m.pk)
        if (inx > -1) {
          this.customer = this.customers[inx]
          //this.getCountry()
        }
      }
    },
    'order.date': {
      handler(val) {
        if (
          this.order.promise_date !== null &&
          this.order.promise_date !== null &&
          new Date(val) > new Date(this.order.promise_date)
        ) {
          this.order.promise_date = null
        }
      }
    },
    internalChange: {
      handler(val) {
        if (val) {
          this.submit()
        }
      }
    }
  },
  mounted() {
    this.getOrder(this.$route.params.code)
    if (this.order.mainStatus === 'in_process') {
      this.getBalance()
    }
  },
  methods: {
    async getCartlist() {
      const cart = await this.$api.sale.product.show({
        pk: this.order.pk,
        opt: { params: { order: true } }
      })
      this.cartlist = cart.data
      if (this.cartlist.length == 0 && this.order.mainStatus == 'quote') {
        this.$toast.error(`${this.$tc('select_prod', 1)}`)
      }
    },
    changeRoute(item) {
      this.$router.push({
        name: 'customer-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    addCustomer(evt) {
      this.customers = [evt]
      this.order.customer = evt.pk
    },
    async getCustomer() {
      this.isLoading = true
      try {
        if (this.customerToSale !== null) {
          if (this.customerToSale?.permanent_customer) {
            this.permanent_customer = this.customerToSale.permanent_customer
          }
          this.order.customer = this.customerToSale.pk
          this.customer = this.customerToSale
        }

        // Consulta
        const customers = await this.$api.customer.list({
          opt: {
            params: {
              permanent_customer: this.permanent_customer,
              company_id: this.company.pk,
              active: true
            }
          }
        })

        this.customers = customers.data.results
        this.$store.commit('sales/customerToSale', null)
      } finally {
        this.isLoading = false
      }
    },
    /**
     * sortCustomers
     * Método para guardar el id del cliente en el $store y redirigir a
     * crear orden de venta
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async sortCustomers() {
      const customers = await this.$api.customer.list({
        opt: {
          params: {
            permanent_customer: this.permanent_customer,
            company_id: this.company.pk,
            active: true
          }
        }
      })
      this.customers = customers.data.results
    },
    async getCountry() {
      if (this.order.customer != null) {
        for (let i in this.customer.address_set) {
          let pk = this.customer.address_set[i].country
          if (this.countries[pk] == undefined) {
            try {
              const country = await this.$api.country.show({
                pk: pk
              })
              this.$set(this.countries, pk, country.data.name)
            } catch (error) {
              console.error(error)
            }
          }
        }
      }
    },
    back() {
      if (this.step === 2 && this.order.mainStatus !== 'draft') {
        this.getCartlist()
      }
      this.step -= this.step === 3 && this.order.mainStatus !== 'draft' ? 2 : 1
    },
    next() {
      this.notification = false
      if (this.step === 1 && this.order.mainStatus === 'draft') {
        this.step = 2
      } else {
        if (this.step === 1 && this.order.mainStatus !== 'draft') {
          this.step = 3
        } else {
          if (this.step === 2) {
            this.getCartlist()
            if (this.order.mainStatus === 'draft') {
              this.order.mainStatus = 'quote'
              this.submit()
            }
            this.step = 3
          } else {
            this.step += 1
          }
        }
      }
    },
    /**
     * showNext
     * Método para determinar si se puede avanzar al siguiente paso
     * en función del estado principal de la orden y el paso actual.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    showNext() {
      const { mainStatus } = this.order
      const isDraftOrQuote = mainStatus === 'draft' || mainStatus === 'quote'

      if (mainStatus === 'delivery' && this.step > 4) return false
      if (mainStatus === 'in_process' && this.step === 6) return false

      if (isDraftOrQuote) {
        return this.step < 3 && (this.order.pk || this.step > 1)
      }
      return this.step < 7
    },
    addProducts() {
      this.step = 2
    },
    async getBalance() {
      await this.$api.invoice
        .show({
          pk: this.order.pk,
          opt: {
            params: {
              order: true
            }
          }
        })
        .then((res) => {
          this.balance = res.data.balance
        })
    },
    async getOrder() {
      let validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      let step = this.codification({
        mode: 'decode',
        code: this.$route.params.step
      })
      this.step = step > 0 ? 2 : 1
      if (this.$store.getters['utils/getIsloading']) {
        this.$store.dispatch('utils/actionLoading')
      }
      if (validation !== 'create' || this.order.pk !== '') {
        if (this.order.pk !== '') {
          this.step = 3
        }
        try {
          this.loading = true
          let response = await this.$api.sale.show({
            pk: this.order.pk !== '' ? this.order.pk : validation
          })
          this.order = response.data
          if (this.order.mainStatus === 'in_process') {
            this.getBalance()
          }
        } finally {
          this.loading = false
        }
      } else {
        this.order = {
          pk: '',
          nameOrder: '',
          date: new Date().toISOString().substr(0, 10),
          code: '',
          customer: null,
          salesman: this.me.id,
          name_salesman: `${this.me.last_name} ${this.me.first_name[0]}.`,
          mainStatus: 'draft',
          tax: 0,
          subtotal: 0,
          total: 0,
          delivery: 0,
          discount: 0,
          dateDone: null,
          partialDelivery: false,
          promise_date: null,
          thumbnail: null,
          company_id: null
        }
      }
      try {
        this.imageUrl = this.order.thumbnail
        this.loadingIMG = false
      } catch (error) {
        this.imageUrl = require('@/assets/default_image.png')
        this.loadingIMG = false
      }
      if (this.order.customer != null) {
        try {
          let customer = await this.$api.customer.show({
            pk: this.order.customer
          })
          this.customers = [customer.data]
          this.customer = customer.data
          //this.getCountry()
        } catch (err) {
          this.order.customer = null
          this.customer = null
        }
      } else {
        this.getCustomer()
      }
    },
    async submit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.load = true
          let form_data = new FormData()
          this.order.company_id = this.company.pk
          for (var key in this.order) {
            if (this.order[key] == null) {
              continue
            }
            if (typeof this.order.thumbnail !== 'string') {
              this.orderImage = this.order.thumbnail
            }
            form_data.append(key, this.order[key])
            if (
              key == 'thumbnail' &&
              (this.order.thumbnail == null ||
                String(this.imageUrl).indexOf('http') != -1 ||
                String(this.order.thumbnail).indexOf(';base64') != -1 ||
                String(this.order.thumbnail).indexOf('http') != -1 ||
                String(this.imageUrl).indexOf('/sources/') != -1)
            ) {
              form_data.delete('thumbnail')
            }
          }
          this.order.pk !== ''
            ? this.$api.sale.edit({
                pk: this.order.pk,
                form: form_data
              })
            : this.$api.sale
                .create({
                  form: form_data
                })
                .then((response) => {
                  if (this.order.pk === undefined || this.order.pk === '') {
                    this.order = response.data
                    this.step = 2
                  } else if (this.order.pk != '') {
                    if (!this.internalChange) {
                      this.dialog = false
                    }
                    this.internalChange = false
                  }
                  this.getCartlist()
                  this.$emit('done')
                })
          if (this.notification === true) {
            this.$toast.success(
              `${this.$tc('order', 1)} ${this.$tc('edited', 2)}`
            )
          }
          this.notification = true
          this.load = false
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      me: 'session/me',
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      myCompanies: 'session/myCompanies',
      company: 'company/getCompanyData',
      customerToSale: 'sales/getCustomerToSale'
    }),
    completed() {
      return {
        delivery:
          !this.order.partialDelivery ||
          this.cartlist.findIndex((m) => m.pending !== 0) === -1
      }
    },
    /**
     * isBalanceSufficient
     * Método para verificar si el saldo del cliente es suficiente
     * en función de su límite de crédito y el estado de la orden.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    isBalanceSufficient() {
      const { mainStatus } = this.order
      const hasProducts = this.orderBtn.orderproduct && this.orderBtn.delivery
      const hasCustomerCredit =
        this.customer && this.customer.customercredit_set[0] !== undefined
      // Verificación del estado principal y productos
      if (
        (mainStatus === 'delivery' || mainStatus === 'finished') &&
        hasProducts
      ) {
        if (hasCustomerCredit) {
          const creditLimit = this.customer.customercredit_set[0].credit_limit
          const isBalanceSufficient = creditLimit - this.balance >= 0
          return (
            this.balance === '0.00' ||
            (this.customer.has_credit && isBalanceSufficient)
          )
        } else {
          return this.balance === '0.00'
        }
      }
      return false
    },
    orderBtn() {
      return {
        changeprice:
          this.getPermissions([`sales.view_orderchangeprice`]) || this.isAdmin,
        customer:
          this.getPermissions([`customer.view_customer`]) || this.isAdmin,
        customerAdd:
          this.getPermissions([`customer.add_customer`]) || this.isAdmin,
        delivery: this.getPermissions([`sales.view_delivery`]) || this.isAdmin,
        designerorder:
          this.getPermissions([`sales.view_assigneduser`]) || this.isAdmin,
        details:
          this.getPermissions([`product.view_productiondetails`]) ||
          this.isAdmin,
        invoices:
          this.getPermissions([`invoices.view_invoice`]) || this.isAdmin,
        imageapproval:
          this.getPermissions([`sales.view_imageapproval`]) || this.isAdmin,
        imageparameters:
          this.getPermissions([`sales.view_imageparameters`]) || this.isAdmin,
        level: this.getPermissions([`base_config.view_level`]) || this.isAdmin,
        orderproduct:
          this.getPermissions([`sales.view_orderproduct`]) || this.isAdmin,
        orderdetails:
          this.getPermissions([`sales.view_orderdetails`]) || this.isAdmin,
        orderstatus:
          this.getPermissions([`sales.view_orderstatus`]) || this.isAdmin,
        product: this.getPermissions([`product.view_product`]) || this.isAdmin,
        quotation:
          this.getPermissions([`sales.view_quotation`]) || this.isAdmin,
        suborderdate:
          this.getPermissions([`sales.view_suborderdate`]) || this.isAdmin,
        terms: this.getPermissions([`base_config.view_term`]) || this.isAdmin,
        typedate: this.getPermissions([`sales.view_typedate`]) || this.isAdmin
      }
    },
    /**
     * setModalTitle
     * Método para asignarle el valor al título de la tabla
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    setSalePKTitle() {
      if (this.order.pk !== '') {
        return `(${this.$t(this.order.mainStatus)})
          ${this.$tc('sale_order', 1)}
          ${this.order.code}`
      }
      return `(${this.$t(this.order.mainStatus)})
        ${this.$t('add', { value: this.$tc('sale_order', 1) })}`
    }
  }
}
</script>
<style lang="sass">
.salepk-arrow
  // -------------------
  // Contenedor de botón
  // -------------------
  display: block
  min-width: 64px
.flex-title
  display: flex
  gap: 10px
  align-items: center
  justify-content: space-between
  text-transform: uppercase
.salesman-info
  background-color: var(--v-auxbg-base)
  border-top-left-radius: 10px
  bottom: 0
  padding: 7px 10px
  position: fixed
  right: 0
  z-index: 1
#salePk #go-to
  bottom: 45px
  right: 2vw
</style>
